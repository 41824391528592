<template>
	<a @click.prevent="openBookingEngine({ ...$props })">
		<slot />
	</a>
</template>

<script setup>
import { BookingEngineFactory } from '@becurious/mews';

const { locale } = useI18n();
const config = useRuntimeConfig();
const bookingEngine = useState('bookingEngine', () => {});

const configurationIds = [
	'83904260-f3f3-4db4-bf5b-ae070107b706', // Apartments de Hallen
	'a2f6e245-7926-4a48-a76a-ae2200e3a892', // Hotel de Jonker
	'5f3322b8-bde0-46c1-bc14-af1f00abdcda', // Hotel Pontsteiger
	'51b88dcc-0434-4c50-91a9-aeaf00fbd75e', // Hotel van de Vijsel
	'2debe8c2-f36f-4d0a-85ae-aee100871abd', // Hotel Roemer
	'9f4ab261-ec92-4621-85c9-aef30109a884', // Hotel JL No76
	'8ed1040f-f777-4484-be9d-ae0500a094cc', // Hotel de Hallen
	'b5871c96-61b8-4acd-ba18-af5700f7ce28', // Hotel Mai
	'13c6faf8-331f-40ba-871d-af3b00bf2c79', // Hotel Mercier
	'94969adb-cf28-4229-9934-af4200a373de', // Hotel Monastère
];

const props = defineProps({
	language: { type: String, default: '' },
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	pax: { type: String, default: '' },
	packageid: { type: String, default: '' },
	promo: { type: String, default: '' },
	roomid: { type: String, default: '' },
	hotelId: { type: String, default: '' },
});

useHead({
	script: [
		{
			key: 'mews-distributor',
			src: 'https://api.mews.com/distributor/distributor.min.js',
		},
	],
});

const openBookingEngine = (options) => {
	if (!bookingEngine.value) {
		return;
	}

	if (!options.language) {
		options = { ...options, language: locale.value };
	}

	if (props.hotelId) {
		bookingEngine.value.mews.showRooms(props.hotelId);
	}

	bookingEngine.value.visitUrl(options);
};

onMounted(() => {
	if (!bookingEngine.value) {
		const factory = new BookingEngineFactory({
			configurationIds,
			gtmID: config.public.googleTagManagerId,
			primaryColor: '#ffca5e',
			mode: 'widget',
		});

		bookingEngine.value = factory.createBookingEngine();
	}
});
</script>

<style lang="scss" scoped>
a {
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
</style>
